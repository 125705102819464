.auth {
  @apply  h-full flex;
}

.auth-banner {
  @apply hidden sticky top-0 left-0 lg:flex flex-col justify-between w-1/2 h-screen p-12;
  background: linear-gradient(120deg, black, #262262);
}

.auth-header {
  @apply flex flex-col gap-y-4;
  text-align: center;

  & span {
    @apply text-xl text-white font-light;
  }
}

.auth-footer {
  @apply uppercase text-xs text-white;
}

.auth-form {
  @apply flex flex-col gap-y-5 p-12 lg:bg-white text-gray-800 w-full;
  max-width: 500px;
  margin: auto;

  & .auth-form__header {
    text-align: center
  }
  & .submit-button {
    @apply bg-blue-500 text-white px-4 py-3 rounded-lg mt-8 font-bold flex items-center justify-center;
  }
}

.auth-field {
  @apply flex flex-col gap-y-2;

  & input {
    @apply w-full h-12 py-2 px-4 bg-gray-300 border border-gray-200 bg-opacity-30 rounded-lg drop-shadow-sm;
  }

  & span {
    @apply font-semibold;
  }
}

.auth-field.forgot div {
  @apply relative;

  & input {
    @apply pr-10;
  }

  & .btn {
    @apply absolute top-4 right-4 text-indigodark cursor-pointer p-0 m-0 bg-none;
  }
}

.forgot-label {
  @apply flex items-baseline justify-between;
}

.auth-direct {
  @apply self-center w-full text-center bg-gray-200 py-3 px-6 text-gray-600 font-semibold rounded-lg mb-6;

  & a {
    @apply text-gray-900 font-bold;
  }
}
