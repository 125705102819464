.feedbacks {
  @apply ml-10 mr-10 mb-10;
  & .head {
    @apply w-full flex items-center justify-between flex-wrap gap-6 mb-4;
  }

  & .submit-feedback {
    @apply flex items-center gap-2 px-4 py-2 font-semibold text-lg bg-darkcyan text-white rounded-full drop-shadow-md ring-1 ring-sky-500 m-5;
  }

  & .cards {
    @apply mt-12 grid gap-y-12 gap-x-6;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.feedbacks .card {
  @apply relative bg-gray-50 ring-1 ring-gray-100 p-4 pt-12 rounded-lg drop-shadow-sm;

  & .image {
    @apply bg-amber-100 w-max p-2 absolute -top-6 rounded-lg drop-shadow;
  }

  & div {
    @apply flex items-center justify-between;
  }

  & .metadata {
    @apply text-sm text-gray-400 mt-2;
  }
}

.feedback {
  @apply flex flex-col gap-y-6;

  & .detail {
    @apply grid gap-2;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.select-feedback {
  @apply bg-indigodark py-4 px-6 flex items-center rounded-lg flex-wrap w-full lg:w-max gap-4;
}

.feedbackdetails {
  @apply space-y-6 bg-gray-100 p-4 rounded-lg;
}

.feedbackblock {
  @apply space-y-4 bg-white ring-1 ring-gray-300 p-4 rounded-lg;
}
